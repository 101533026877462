import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class ApiService {
   
    // public partnerurl = 'https://localhost:44398/api/';
    // public paymenturl = 'http://localhost:50701/';
    // public vendorurl = 'http://localhost:4539/';

     public authurl = 'https://software.eazipoints.com/auth/';
     public vendorurl = 'https://software.eazipoints.com/vendor/';
     public paymenturl = 'https://software.eazipoints.com/payment/';
     public partnerurl = 'https://software.eazipoints.com/partner/api/';
     public headers: any;
    setHeader() {
      return {
        headers: new HttpHeaders()
          .set('Authorization', `Bearer ${'dev' + 'NSQtQgggVEb8g7dDHqnzRJ3FLzCUQmKM0uAjATGpHX9gNLqDrIwTtBUs5V9CUQF'}`)
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Origin',`*`)

      };
    }
  
    constructor(
      public http: HttpClient,
  
    ) {}

    userLogin(postData: any): Observable<any> {
      console.log("login service data", postData)
      //return this.partner();
     return this.http.post(this.partnerurl + "partner/login", postData);
    }

    partner(): Observable<any> {
      let jso:any;

      return this.http.get(this.partnerurl + "partner/partner");
      //var partners = this.http.get("https://software.eazipoints.com/vendor/vendor/admin");
     
      
    }

    getLicenseKeyCountByPartner(partnerId: string) {
      return this.http.get(this.partnerurl + "partner/licensecount/"+ partnerId);
    }

    GetActiveKeysByPartner(partnerId: any) {
      return this.http.get(this.partnerurl + "partner/activekeybypartner/"+ partnerId);
    }

    getPartnerPlanPrice() {
      return this.http.get(this.partnerurl + "partner/priceconfig");
    }

    getTokenId(postData: any) {
      return this.http.post(this.paymenturl + 'payment/paymenttokenId', postData, this.setHeader());
    }

    updatePaymentActivity(postData:any){
      console.log(postData);
      return this.http.post(this.paymenturl + 'payment/vendoractivity', postData, this.setHeader());
    }
    
    GetMyVendors(partnerId: any) {
      console.log(partnerId);
      return this.http.get(this.vendorurl + 'vendor/vendorsbypartner/'+ partnerId);
    }

    GetMyInvoice(partnerId: any) {
      return this.http.get(this.vendorurl + 'vendor/paymenttransactionbyvendor/'+ partnerId);
    }

    GetInvoiceById(id: any) {
      return this.http.get(this.vendorurl + 'vendor/paymenttransactionbyid/'+ id);
    }


    CreateVendor(postData: any): Observable<any> {
      console.log("register service data", postData)
      //return this.partner();
     return this.http.post(this.vendorurl + "vendor/save", postData);
    }

    ResetPassword(postData:any): Observable<any> {
      console.log("reset password", postData)
     return this.http.post(this.authurl + "auth/reset", postData);
    }
}