<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
   
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
        integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous">
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
    <link rel="stylesheet" href="https://unpkg.com/@icon/themify-icons/themify-icons.css">
    

    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css">
    <link href="https://fonts.googleapis.com/css?family=Montserrat|Arimo|Pridi&display=swap" rel="stylesheet">
   
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
      integrity="sha384-Bfad6CLCknfcloXFOyFnlgtENryhrpZCe29RTifKEixXQZ38WheV+i/6YWSzkz3V" crossorigin="anonymous" />
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
    
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
    <script src="node_modules/image-to-base64/image-to-base64.min.js"></script>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
   
    <script src="https://maxcdn.bootstrcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  
    <script src="http://cdnjs.cloudflare.com/ajax/libs/modernizr/2.6.2/modernizr.min.js"></script>
    
    <script src="http://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/2.3.1/js/bootstrap.min.js"></script>
    <link type='text/css' href='http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400italic,600' rel='stylesheet'>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/jQuery.print/1.6.2/jQuery.print.min.js"></script>
  
    <link rel="stylesheet" type="text/css" href="/src/material-tooltip.css">

    <script type="text/javascript" src="/src/material-tooltip.js"></script>

  </head>
  <div>
    <header id="topnav" class="navbar navbar-default navbar-fixed-top ng-scope">
      <div class="fixed-header" id="myHeader">
        <i class="" style="margin-left: 2%; cursor:pointer;" mat-raised-button
          matTooltip="Side Menu" aria-label="Collapse Menu"></i>
  
        <div>
          <img src="https://software.eazipoints.com/images/EazipointsNewLogo.png" style="height: 50px;" alt="eazipoints" class="Logo">
        </div>
      </div>
      <div class="iColor" id="nav-resp">

        <button class="w3-button w3-middle w3-circle" style="margin-top: -2%;" mat-raised-button
        matTooltip="Dashboard" (click)="showDashboard()" aria-label="Dashboard">
        <i class="material-icons">dashboard</i>
      </button>

        <button class="w3-button w3-middle w3-circle" style="margin-top: -2%;" 
          mat-raised-button matTooltip="My Client"  (click)="showMyClient()" aria-label="My Client">
          <i class="material-icons">supervised_user_circle</i>
        </button>

        <button class="w3-button w3-middle w3-circle" style="margin-top: -2%;" 
          mat-raised-button matTooltip="My Invoice"  (click)="showMyInvoice()" aria-label="My Invoice">
          <i class="material-icons">receipt</i>                            
        </button>
       
        <button class="w3-button w3-middle w3-circle" style="margin-top: -2%;" mat-raised-button
          matTooltip="User Logout" aria-label="Logout User" (click)="signout()">
          <i class="material-icons">account_circle</i>
        </button>
      </div>
    </header>
  
  </div>
  <!-- <div id="wrapper">
    <div id="layout-static">
        <div class="static-sidebar-wrapper sidebar-default">
            <div class="static-sidebar">
                <div class="sidebar">
                    <div class="widget">
                        <div class="widget-body" id="widget-body">
                            <div class="userinfo">
                                <div class="vendorAvatar" id="vendorAvatar">
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget stay-on-collapse" id="widget-sidebar" style="position:relative; top:20px">
                        <nav role="navigation" class="widget-body">

                          <ul class="nav nav-tabs acc-menu">

                            <li class="nav-tabs selectedTab" id="ordersStatus">
                                <a id="orderDashboard" href="#tab-orderDashboard" style="font-weight:bold" role="tab" data-toggle="tab" aria-controls="#tab-orderDashboard" aria-selected="false">
                                    <i class="fas fa-clipboard-list" style="color: #03a9f4;" data-toggle="tooltip" data-placement="top" title="Orders"></i> &nbsp; Orders
                                </a>
                            </li>
                            <li class="nav-tabs selectedTab" id="ordersStatus">
                              <a id="orderDashboard" href="#tab-orderDashboard" style="font-weight:bold" role="tab" data-toggle="tab" aria-controls="#tab-orderDashboard" aria-selected="false">
                                  <i class="fas fa-clipboard-list" style="color: #03a9f4;" data-toggle="tooltip" data-placement="top" title="Orders"></i> &nbsp; Orders
                              </a>
                          </li>
                            </ul>
                        </nav></div>
                </div></div></div></div></div> -->

<body class="topbody">

  <!-- <div class="main">
    <div class="container-fluid">
      <div class="container"> -->
        <div *ngIf="dashboard" class="panel panel-primary">
          <div class="panel-heading">
            <h2>Dashboard</h2>
        </div>
        <div class="panel-body">
          <div class="col-md-12">
            <div class="col-md-6">
              <div class="panel reportPanel">
                <div class="row" style="margin:30px">
                  <!-- <p class="user-data">{{name}}</p> -->
                  <div class="col-md-6">
                    <div class="info-dashboardtoday info-tile-alt tile-info">
                      <div class="tile-body tileContentSize">
                        <span class="contentBold">Active License</span>
                      </div>
                      <div class="stat-dashboard">
                        <div class="tile-dashboardicon"><i class="fas fa-solid fa-id-card"></i></div>
                        <div class="tile-dashboardheading"><span>{{activeLicenseKeysCount}}</span></div>
                    </div>
                      
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="info-dashboardtoday info-tile-alt tile-info">
                      <div class="tile-body tileContentSize">
                        <span class="contentBold">Expired License</span>
                      </div>
                      <div class="stat-dashboard">
                        <div class="tile-dashboardicon"><i class="fas fa-solid fa-id-card"></i></div>
                        <div class="tile-dashboardheading"><span>{{expiredLicenseKeysCount}}</span></div>
                    </div>
                      
                    </div>
                  </div>
                
                  
                  
                </div>
                <br>
              </div>
            </div>
            <div class="col-md-6">
              <div class="panel reportPanel">
                <div class="row" style="margin:30px">
  
                  <div class="row">
                    <div class="col-md-8">
                    
                  
                      <div *ngFor="let price of priceList">
                        <span class="offerlabel">Purchase {{price.numberOfLicenseMin}} to {{price.numberOfLicenseMax}} License Get {{price.discountPercent}}% Offer</span>
                        <!-- <div *ngIf="price.numberOfLicenseMax > '1'">
                          
                        </div> -->
                        
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="info-tile tile-danger" style="visibility: visible; opacity: 1; display: block; transform: translateY(0px);">
                                            
                        <div class="tile-heading"><span>Amount</span></div>
                        <div class="tile-body tileContentSize tilealign">
                          <span>₹</span>
                      <span class="contentBold" id="price">0</span>
                        </div>
                        
                    </div>
                      <!-- <div class="info-tile-alt tile-info">
                        <div class="tile-body tileContentSize">
                          <span class="contentBold" id="price"></span>
                        </div>
                        
                        
                      </div> -->
                    </div>
                    
                  </div>
                  
                 
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        
                        <div class="col-xs-12">
                            <div class="input-group">
                              <input type="number" min="1" max="50" placeholder="Enter No of Purchase License" class="form-control" id="numberoflicense"> 
                              <!-- (blur)="calculation($event)" -->
                                    <div class="input-group-btn">
                                            <button type="submit" class="btn btn-primary" id="btnExpireDay" (click)="calculation()">Check</button>
                                    </div>
                            
                            </div>
      
                        </div>
                    </div>
                     
                      <!-- <span >amount</span> -->
                    </div>
                    <div class="col-md-4">
                      <button type="submit" class="btn btn-primary pull-right" id="btnExpireDay" (click)="payment()">Pay</button>
                    </div>
                  </div>
                  
                  
                  </div>
              </div>
            </div>
          </div>
          
          
          <div class="col-md-6">
            <div class="panel reportPanel">
              <div class="panel-heading">
                <h2>Active License Keys</h2>
              </div>
              <div class="row" style="margin:30px" *ngFor="let key of activeKeys">
                <div class="input-group">
                  <span class="form-control keys">{{key}} </span>
                  <div class="input-group-btn">
                    <button class="btn btn-primary btnkeys" id={{key}} (click)="myCopyKey(key)">Copy</button>
                  </div>
                </div>
                
                
                

              </div>
               
            </div>

          </div>
        </div>
        </div>

    
 <div *ngIf="client" class="panel panel-primary">
  
    <div class="panel-heading">
      <h2>Client Details</h2>
  </div>
  <div class="panel-body">
    <div class="col-md-12">
    
    <div class="col-md-8">
      <div class="panel reportPanel">
        <div class="panel-heading">
          <h2>My Clients</h2>
      </div>
      <div class="panel-body">
      
        <div class="table-responsive">
          <table id="table_id" class="table table-bordered custab">
            <thead class="thead">
                <tr>
                    <th>Vendor Name</th>
                    <th>Phone Number</th>
                    <th>Active From</th>
                    <th>Active Till</th>
                    <th>LicenseKey</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody  *ngFor="let vendor of clients">
                <tr>
                    <td>{{vendor.vendorName}}</td>
                    <td>{{vendor.phoneNumber}}</td>
                    <td>{{vendor.activeFrom | date:'dd-MMM-yyyy'}}</td>
                    <td>{{vendor.activeTill | date:'dd-MMM-yyyy'}}</td>
                    <td>{{vendor.licenseKey}}</td>
                    <td *ngIf="vendor">Active</td>
                    <td *ngIf="!vendor">Not Active</td>
                  
                </tr>
              
            </tbody>
        </table>
        </div>
        
        </div>
      </div>

    </div>
    <div class="col-md-4">
      <div class="panel reportPanel">
        <form id="clientform" name="form" [formGroup]="vendorRegisterForm"
            (ngSubmit)="vendorRegisterForm.valid && registerClient()" class="form-horizontal">
        <div class="panel-heading">
          <h2>Add Client</h2>
      </div>
      <div class="panel-body">
        <div class="col-md-12">
          <div class="panel-body">
            
            <div class="form-group mb-md">
              <div class="col-xs-12">
                  <div class="input-group">
                      <span class="input-group-addon">
                          <i class="ti ti-user"></i>
                      </span>
                      <input id="user" type="text" class="form-control" name="user" value=""
                          placeholder="Salon Name" formControlName="VendorName">
                  </div>
                      
              </div>
          </div>
          <div class="form-group mb-md">
            <div class="col-xs-12">
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa-regular fa-mobile"></i>
                    </span>
                    <input id="user" type="text" class="form-control" name="user" value=""
                        placeholder="Phone Number" formControlName="PhoneNumber">
                </div>
                    
            </div>
        </div>
            <div class="form-group mb-md">
                <div class="col-xs-12">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="ti ti-email"></i>
                        </span>
                        <input id="user" type="text" class="form-control" name="user" value=""
                            placeholder="Email Address" formControlName="EmailAddress">
                    </div>
                        
                </div>
            </div>

            <div class="form-group mb-md">
                <div class="col-xs-12">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="ti ti-key"></i>
                        </span>
                        <input id="License" type="text" class="form-control" name="license"
                            placeholder="License Key" formControlName="LicenseKey">
                    </div>
                        
                </div>
            </div>
            

            </div>
        </div>
        </div>
        <div class="panel-footer">
          <div class="clearfix">
              <button class="btn btn-primary pull-right" [disabled]="!vendorRegisterForm.valid">Register</button>
          </div>
      </div>

        </form>
    </div>
    </div>
  </div>  
</div>
</div>

<div *ngIf="invoice">
    <div class="panel panel-primary reportPanel">
      <div class="panel-heading">
        <h2>My Invoice</h2>
    </div>
    <div class="panel-body">
    
      <div class="table-responsive">
        <table id="table_id" class="table table-bordered custab">
          <thead class="thead">
              <tr>
                  <th>Invoice Number</th>
                  <th>Purchased Date</th>
                  <th>No Of Keys</th>
                  <th>Amount</th>
                  <th>Payment Mode</th>
                  <th>View</th>
              </tr>
          </thead>
          <tbody  *ngFor="let invoice of invoiceList">
              <tr>
                <td>{{invoice.orderId}}</td>
                  <td>{{invoice.orderdate | date:'dd-MMM-yyyy'}}</td>
                  <td>{{invoice.promotionalSmsCount}}</td>
                  <td>{{invoice.amount}}</td>
                  <td>{{invoice.paymentMode}}</td>
                  <td><button class="myteambtn btn btn-primary" (click)="GetInvoiceById(invoice.id)">View</button></td>
              </tr>
            
          </tbody>
      </table>
      </div>
      
      </div>
    </div>
</div>

<div *ngIf="viewinvoice" class="panel panel-primary">
  <div class="panel-heading">
    <h2>Invoice Details</h2>
  </div>
  <div class="panel-body">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-0">
          <div class="row">
            <!-- <div class="col-md-1">
                <img src="https://software.eazipoints.com/images/EazipointsNewLogo.png" style="width:100px;">
            </div> -->
            <div class="col-md-6">
              <img src="https://software.eazipoints.com/images/EazipointsNewLogo.png" style="width:40%;">
                <br />
                <b>EAZIPOINTS SYSTEMS PVT LTD</b><br>
                <b>No 4/26, Parathsarathy Salai</b><br>
                <b>Nanmangalam</b><br>
                <b>Chennai-600129</b><br>
                <b>GST 33AAECE2638M1ZK</b>
            </div>
  
            <div class="col-md-5 text-right">
                <p class="font-weight-bold mb-1">Invoice #{{invoicedata.orderId}}</p>
                <p class="text-muted">Date: {{invoicedata.orderdate | date:'dd-MMM-yyyy'}}</p>
            </div>
        </div>
  
        <hr class="my-5">
        <div class="row pb-5 p-5">
          <div class="col-md-6">
              <p class="mb-1">{{name}}</p>
              <p class="mb-1">{{gst}}</p>
          </div>
  
          <div class="col-md-6 text-right">
              <p class="font-weight-bold mb-4">Payment Details</p>
              <p class="mb-1"><span class="text-muted">Bank Reference Number: </span> {{invoicedata.bankRefNo}}</p>
  
              <p class="mb-1"><span class="text-muted">Payment Type: </span> {{invoicedata.paymentMode}}</p>
  
          </div>
      </div>
      <div class="row p-5">
        <div class="col-md-12">
            <table class="table">
                <thead>
                    <tr>
                        <th class="border-0 text-uppercase small font-weight-bold">ID</th>
                        <th class="border-0 text-uppercase small font-weight-bold">Description</th>
                        
                            <th class="border-0 text-uppercase small font-weight-bold">No Of Key</th>
                        
                        <th class="border-0 text-uppercase small font-weight-bold text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>{{invoicedata.usageType}}</td>
                        
                            <td>{{invoicedata.promotionalSmsCount}}</td>
                        
                        <td class="text-right">{{invoicedata.amount}}</td>
                    </tr>
  
  
                </tbody>
            </table>
        </div>
    </div>
        <div class="row invoicesummary">
      <div class="col-md-10 text-right">Sub Total</div>
      <div class="col-md-2 text-right">₹ {{invoicedata.subTotal}}</div>
  </div>
  <div class="row invoicesummary">
      <div class="col-md-10 text-right">CGST(9%) + SGST(9%)</div>
      <div class="col-md-2 text-right">₹ {{invoicedata.taxAmount}}</div>
  </div>
  <div class="row invoicetotal">
      <div class="col-md-10 text-right">Total</div>
      <div class="col-md-2 text-right">₹ {{invoicedata.amount}}</div>
  </div>
        </div>
      </div>
      
  </div>
  </div>
  <div class="panel-footer">
    <div class="clearfix">
      <button class="btn btn-primary pull-right" (click)="printinvoice()">Print</button>
  </div>
  </div>

</div>
          
            
  
            <style>
              .panel .panel-body {
    background-color: #fff;
    font-size: 14px;
    padding: 16px;
  }
  .panel .panel-body.no-padding .alert {
    margin: 32px !important;
  }
  .panel .panel-body.panel-tb-padding {
    padding: 16px 0px;
  }
  .panel .panel-body.panel-lr-padding {
    padding: 0 16px;
  }
  .panel .panel-body.scroll-pane > .scroll-content {
    padding: 16px;
  }
  .panel .panel-footer {
    padding: 16px;
    background-color: #fafafa;
    border-radius: 0 0 2px 2px;
  }

  .tile-heading{
    text-align: left;
    color: #9e9e9e;
    height: 16px;
    line-height: 16px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .tilealign{
    text-align: right;
  }

  .offerlabel{
    font-size: 13px;
    font-style: initial;
    color: black;
    font-weight: bold;
  }

  .keys{
    font-weight: bold;
    color: #999;
  }


  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .iColor {
    text-align: end;
    /* margin-top: -1%; */
    margin-right: 2%;
    color: #337ab7!important;
  }

  tr,
th {
  border: 2px solid white;
}
table {
  border: 1px solid black;
}

.custab{
  border: 3px solid #607d8b73;
  padding: 5px;
  transition: 0.5s;
  color: black;
  /* font-family: 'Hind Madurai', sans-serif;
  box-shadow: 0px 0px 6px 2px lightblue; */
  
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  
  padding: 15px;
  
  }
  .thead{
    background-color: #607d8b73;
    border-color: #607d8b73;
  }

  .mat{
    color:red;
  }

  .tooltip-inner {
    background-color: #00cc00 !important;
  }
            </style>
          
       
        

        
        <ngx-spinner bdOpacity=0.9 bdColor="#333" size="medium" color="#24B6F0" type="ball-atom" [fullScreen]="true">
          <p style="color: white; margin-top: 60%;"> EaziPoints... </p>
      </ngx-spinner>
       </body> 
        
        
      <!-- </div>
      
          </div>
  </div> -->


