import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { FormControl, FormBuilder, FormArray, Validators, FormGroup, FormControlName } from "@angular/forms";
 import { ToastrService } from 'ngx-toastr';
declare var Layer: any;



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  title = 'reloadComponent';

  constructor(
    public apiservice: ApiService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService
  ) {
    this.id = localStorage.getItem('Id');
    this.name = localStorage.getItem('Name');
    this.phone = localStorage.getItem('Phone');
    this.email = localStorage.getItem('Email');
    this.gst = localStorage.getItem('GST')

   }

   public vendorRegisterForm = new FormGroup({
    VendorName: new FormControl('', [Validators.required,]),
    PhoneNumber: new FormControl('', [Validators.required,]),
    EmailAddress: new FormControl('', [Validators.required, Validators.email]),
    LicenseKey: new FormControl('', [Validators.required,])
    
  });

  ngOnInit(): void {
   

    this.getPartnerPlanPrice();
    this.getLicenseKeyCountByPartner();
    this.getActiveLicenseKeys();
    this.showDashboard();
    this.GetMyInvoice();
    
  }
  id: any;
  name: any;
  phone: any;
  email:any;
  gst:any;
  priceList:any;
  activeLicenseKeysCount:any;
  expiredLicenseKeysCount:any;
  activeKeys:any;
  planPrice:any;
  noOfLicense:any;
  price:any;
  dashboard: any;
  client:any;
  clients:any;
  planId:any;
  invoice:any;
  invoiceList:any;
  viewinvoice:any;
  invoicedata:any;
  

  registerClient(){
    this.spinner.show();
    let postData = {
      VendorName: this.vendorRegisterForm.value.VendorName,
      PhoneNumber: this.vendorRegisterForm.value.PhoneNumber,
      EmailAddress: this.vendorRegisterForm.value.EmailAddress,
      LicenseKey: this.vendorRegisterForm.value.LicenseKey,
      PartnerId: this.id,
      Plan:"Premium Yearly",
      country:"India"
    };
    this.dashboard = false;
    this.client = true;
    this.apiservice.CreateVendor(postData).subscribe((resp: any) => {
 
      this.apiservice.ResetPassword([resp.id, resp.vendorPassword]).subscribe((resp: any) =>{
        
        this.spinner.hide();
        this.toastr.success('Client Registered Successfully!');
        //alert('Client Registered Successfully!');
        //window.location.reload();
        this.GetMyClients();
        this.vendorRegisterForm.reset();
      })
    },
    error => {
      
      this.toastr.error(error.error);
      this.spinner.hide();
      
    });

    console.log(postData);
  }

  GetInvoiceById(id:any){
    this.viewinvoice = true;
    this.invoice = false;
    
    this.apiservice.GetInvoiceById(id).subscribe((resp:any)=>{
      console.log(resp);
      this.invoicedata = resp;
    });

  }

  printinvoice(){
    $(".panel-heading").addClass('hidden');
    $(".panel-footer").addClass('hidden');
   window.print();
   $(".panel-heading").removeClass('hidden');
   $(".panel-footer").removeClass('hidden');
  }

  getPartnerPlanPrice(){
    this.apiservice.getPartnerPlanPrice().subscribe((resp: any) => {
    this.priceList = resp;
      console.log(resp);
      
      // this.toastrService.success('Plan price get');
    })
  }

  getActiveLicenseKeys(){
    this.apiservice.GetActiveKeysByPartner(this.id).subscribe((resp: any) => {
    this.activeKeys = resp;
      console.log(resp);
    })
  }

  getLicenseKeyCountByPartner(){
    this.apiservice.getLicenseKeyCountByPartner(this.id).subscribe((resp: any) => {
      console.log(resp)
     this.activeLicenseKeysCount = resp.activeKeysCount;
     this.expiredLicenseKeysCount = resp.expiredKeysCount;
    })
  }

  myCopyKey(key:any){
   
    navigator.clipboard.writeText(key);
    this.toastr.success('License Key Copied Successfully!');
    $('.btnkeys').html('Copy');
    $('#'+key).html('Copied');
    

  }

  showDashboard(){
    this.dashboard = true;
    this.client = false;
    this.invoice = false;
    this.viewinvoice = false;
  }

  showMyInvoice(){
    this.dashboard = false;
    this.client = false;
    this.invoice = true;
    this.viewinvoice = false;
  }

  showMyClient(){
    this.spinner.show();
    this.dashboard = false;
    this.client = true;
    this.invoice = false;
    this.viewinvoice = false;
    this.GetMyClients();
    this.spinner.hide();
  }

  signout(){
    localStorage.clear();
    this.router.navigate(['login']);
  }

  GetMyClients(){
    this.apiservice.GetMyVendors(this.id).subscribe((resp: any) => {
      console.log(resp)
      this.clients = resp;
    })
  }

  GetMyInvoice(){
    this.viewinvoice = false;
    this.apiservice.GetMyInvoice(this.id).subscribe((resp: any) => {
      console.log(resp)
      this.invoiceList = resp;
    })
  }
  
  calculation(){
    this.spinner.show();
    this.noOfLicense = $('#numberoflicense').val();
    this.priceList.forEach((element:any) => {
      if((this.noOfLicense >= element.numberOfLicenseMin) && this.noOfLicense <= element.numberOfLicenseMax)
      {
            console.log(element.price);
            var price = element.price * this.noOfLicense;
            $('#price').text(price);
            this.planPrice = element.price;
            this.price = price;
            this.planId = element.planId;
      }
    });
    this.spinner.hide();
  }

   payment(){
    
    this.calculation();
    //var Amount = $('#price').text();
    this.spinner.show();
    let tokenPostData = 
            {
                Amount : this.price,
                EmailAddress : this.email,
                PhoneNumber : this.phone,
                VendorId : this.id,
                ParentId : this.id,
                UsageType : "License Key",
                PromotionalSmsCount : this.noOfLicense,
                TransactionalSmsCount : 0,
                PlanPrice : this.planPrice,
                Plan : this.planId
                
                
            };

            this.apiservice.getTokenId(tokenPostData).subscribe((resp: any) => {
              console.log("AccessKeyAndTokenResp: ", resp);
              this.completePayment(resp);
              this.spinner.hide();
              
             
            },
              error => {
                //this.spinner.hide();
                console.log("TokenAndAccessKeyGenerationError: ", error);
              });
          }
  
          completePayment(resp: { token: any; accesskey: any; }) {
            Layer.checkout({
              token: resp.token,
              accesskey: resp.accesskey
            },
        
              (response: { status: string; payment_token_id:string; }) => {
        
                console.log("data of response from the payment :", response)
                if (response.status == "captured") {
                
                 this.apiservice.updatePaymentActivity([response.payment_token_id]).subscribe((resp: any) => {
                  console.log("PaymentUpdation: ", resp);
                  this.spinner.hide();
                  //alert('License Key Generated Successfully!');
                  this.toastr.success('License Key Generated Successfully!');
                  window.location.reload();
                  //alert('License Key Generated');
                  // 
                 
                },
                  error => {
                    this.spinner.hide();
                    this.toastr.error(error.error);
                    console.log("TokenAndAccessKeyGenerationError: ", error);
                  });
              }
                else if (response.status == "created") { }
                else if (response.status == "pending") { }
                else if (response.status == "failed") {
                  
                }
                else if (response.status == "cancelled") {
                 
                }
              },
              function (err: any) {
                //integration errors
                
                console.log("PaymentCheck: ", err);
              });
          }

}
