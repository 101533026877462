<head>
    <link href="https://fonts.googleapis.com/css?family=Montserrat|Arimo|Pridi&display=swap" rel="stylesheet">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
        integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous">
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
    <link rel="stylesheet" href="https://unpkg.com/@icon/themify-icons/themify-icons.css">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css"> -->
    <!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script> -->
    <!-- <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script> -->
</head>


<!-- <body>

    <div class="col-md-3"></div>
    <div style="text-align:center">
        <div class="focused-form container ng-scope">

            <img src="/assets/img/Nokia-logo.png" alt="Nokia" class="Nokia-Logo">
            <div class="row">
                <div class="col-md-4 col-md-offset-4">
                    <div class="panel panel-default">

                        <p class="Sign-in">SIGN IN</p>

                        <div>
                            <div *ngIf="Invalid" style="margin-top: 23px;">
                                <div *ngIf="logindata">
                                    <p class="failed"><i class="ti ti-close"></i>&nbsp;{{logindata.message}}</p>
                                </div>
                            </div>

                            <div style="margin-left:10px;float:left;" class="formEmail">
                                <i class="ti ti-user icons"></i>
                                <input class="form-controls" type="text" placeholder="e-Mail" name="email"
                                    [(ngModel)]="email">
                            </div>

                            <div style="margin-left:10px;float:left;margin-top:30px;" class="formPassword">
                                <i class="ti ti-key icons"></i>
                                <input class="form-controls" type="password" placeholder="Password" name="password"
                                    [(ngModel)]="password">
                            </div>

                            <p class="forgot-pwd" (click)="forgetPwd()">Forgot Password?</p>

                            <div class="login-footer">


                                <button class="log-btn" (click)="loginsave()"> <i class="fa fa-sign-in"></i>&nbsp;
                                    LOGIN</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body> -->

<style>
    #loginbox {
        margin-top: 60px;
    }

    #loginbox>div:first-child {
        padding-bottom: 10px;
    }

    .iconmelon {
        display: block;
        margin: auto;
    }

    #form>div {
        margin-bottom: 25px;
    }

    #form>div:last-child {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .panel-body {
        padding-top: 30px;
        background-color: rgba(2555, 255, 255, .3);
    }

    .iconmelon,
    .im {
        position: relative;
        width: 150px;
        height: 150px;
        display: block;
        fill: #525151;
    }

    .iconmelon:after,
    .im:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /*    radio button*/

    .control {
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
    }

    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .control__indicator {
        position: absolute;
        top: 0px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
    }

    .control--radio .control__indicator {
        border-radius: 50%;
    }

    .control:hover input~.control__indicator,
    .control input:focus~.control__indicator {
        background: #ccc;
    }

    .control input:checked~.control__indicator {
        background: #2aa1c0;
    }

    .control:hover input:not([disabled]):checked~.control__indicator,
    .control input:checked:focus~.control__indicator {
        background: #0e647d;
    }

    .control input:disabled~.control__indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }

    .control__indicator:after {
        content: '';
        position: absolute;
        display: none;
    }

    .control input:checked~.control__indicator:after {
        display: block;
    }

    .control--checkbox .control__indicator:after {
        left: 8px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .control--checkbox input:disabled~.control__indicator:after {
        border-color: #7b7b7b;
    }

    .control--radio .control__indicator:after {
        left: 7px;
        top: 7px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: #fff;
    }

    .control--radio input:disabled~.control__indicator:after {
        background: #7b7b7b;
    }

    .select {
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        width: 100%;
    }

    .select select {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 0;
        border-radius: 0;
        background: #e6e6e6;
        color: #7b7b7b;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .select select::-ms-expand {
        display: none;
    }

    .select select:hover,
    .select select:focus {
        color: #000;
        background: #ccc;
    }

    .select select:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .select__arrow {
        position: absolute;
        top: 16px;
        right: 15px;
        width: 0;
        height: 0;
        pointer-events: none;
        border-style: solid;
        border-width: 8px 5px 0 5px;
        border-color: #7b7b7b transparent transparent transparent;
    }

    .select select:hover~.select__arrow,
    .select select:focus~.select__arrow {
        border-top-color: #000;
    }

    .select select:disabled~.select__arrow {
        border-top-color: #ccc;
    }
</style>

<div class="container">
    <!-- <div class="row">
        <img src="/assets/img/Nokia-logo.png" alt="Nokia" class="img-responsive" style="margin-top: 90px; padding-left: 463px; height: 50px;">
    </div> -->
    <div class="row" style="margin-top: 10%">
        <div class="col-md-4 col-md-offset-4">
            <div class="panel panel-default">
                <form id="loginform" name="form" [formGroup]="loginProfileForm"
                    (ngSubmit)="loginProfileForm.valid && loginsave()" class="form-horizontal">
                    <div class="panel-heading" style="margin: 0px auto; width: fit-content">
                        <!-- <h2>Sign In</h2> -->
                        <img src="https://software.eazipoints.com/images/EazipointsNewLogo.png" alt="Nokia" style="height: 50px;">
                    </div>
                    <div class="panel-body">
                        
                        <div class="form-group mb-md">
                            <div class="col-xs-12">
                                <div class="input-group">
                                    <span class="input-group-addon">
                                        <i class="ti ti-user"></i>
                                    </span>
                                    <input id="user" type="text" class="form-control" name="user" value=""
                                        placeholder="Email id " formControlName="email">
                                             

                                </div>
                                <!-- <div
                                    *ngIf="loginProfileForm.get('email').touched && loginProfileForm.get('email').invalid">
                                    <span class="text-danger ion-padding"
                                        *ngIf="loginProfileForm.get('email').errors.required">
                                        Email Id is required *</span>
                                </div> -->

                            </div>
                        </div>

                        <div class="form-group mb-md">
                            <div class="col-xs-12">
                                <div class="input-group">
                                    <span class="input-group-addon">
                                        <i class="ti ti-key"></i>
                                    </span>
                                    <input id="password" type="password" class="form-control" name="password"
                                        placeholder="Password" formControlName="password">
                                       

                                </div>
                                <!-- <div
                                    *ngIf="loginProfileForm.get('password').touched && loginProfileForm.get('password').invalid">
                                    <span class="text-danger ion-padding"
                                        *ngIf="loginProfileForm.get('password').errors.required">
                                        Password is required *</span>
                                </div> -->

                            </div>
                        </div>

                        <div class="form-group mb-n">
                            <!-- <div class="col-xs-12">
                                <a class="pull-left" style="cursor:pointer;" (click)="forgetPwd()">Forgot password?</a>
                            </div> -->
                        </div>
                    </div>

                    <div class="panel-footer">
                        <div class="clearfix">
                            <button class="btn btn-primary pull-right" [disabled]="!loginProfileForm.valid"><i
                                    class="glyphicon glyphicon-log-in"></i> Login</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<router-outlet></router-outlet>
<!-- <ngx-spinner bdOpacity=0.9 bdColor="#333" size="medium" color="#24B6F0" type="ball-atom" [fullScreen]="true">
    <p style="color: white; margin-top: 60%;"> EaziPoints... </p>
</ngx-spinner> -->