import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormArray, Validators, FormGroup, FormControlName } from "@angular/forms";
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  logindata: any;
  email: any;
  password: any;
  success: any;
  
  public loading = false;
  undefinedmessage: any;
  undefinedpassmessage: any;

  constructor(
    public apiservice: ApiService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService
  ) { }
  public loginProfileForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required,])
    
  });

  ngOnInit() {
  }
  
  loginsave(){
    let postData = {
      "UserId": this.loginProfileForm.value.email,
      "Password": this.loginProfileForm.value.password,
     
      //platform:"web"
    } 
    this.spinner.show();
    if (((postData.UserId == '') || (postData.UserId == undefined))) {
      this.undefinedmessage = "! Please enter a user id";
      setTimeout(() => {
        this.undefinedmessage = false;
      }, 8000);
      console.log("undefined:", this.undefinedmessage);
    }
    if ((postData.Password == '') || (postData.Password == undefined)) {
      this.undefinedpassmessage = "! Please enter a password";
      setTimeout(() => {
        this.undefinedpassmessage = false;
      }, 8000);
      console.log("undefined:", this.undefinedmessage);
    }
    else {

     // this.router.navigate(['plan']);
      this.apiservice.userLogin(postData).subscribe(resp => {
       
        // this.apiservice.LoginUser(data);
        // console.log(data.headers.get('token'))
        console.log("LoginResponse: ", resp);
        if(resp != null){
          this.router.navigate(['dashboard']);
          // this.logindata = data;
          this.spinner.hide();
          let serializeData = JSON.stringify(resp);
          localStorage.setItem('loginResData', serializeData);
          localStorage.setItem('Id', resp.id);
          localStorage.setItem('Name', resp.firstName);
          localStorage.setItem('Phone', resp.phoneNumber);
          localStorage.setItem('Email', resp.email);
          localStorage.setItem('GST', resp.gstNumber);
  
        }else{
          this.toastr.error('Invalid username or password');
          this.spinner.hide();
         // alert('Invalid username or password')
        }
        
        
       
      },
      error => {
        this.spinner.hide();
        this.toastr.error(error.error);
       
      }
      
      );


  }
}


}
